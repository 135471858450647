const geneSetAddForm = {
    id: {
        value: null,
        valid: true
    },
    setName: {
        label: 'Set Name',
        description: 'set name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: false,
            hint: 'Enter set name'
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    setSize: {
        label: 'Set Size',
        description: 'set size',
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: false
        },
        valid: false,
        touched: false
    },
    pubMedId: {
        label: 'PubMed ID',
        description: 'pub med',
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: '',
            hint: 'Enter a numeric value',
            disabled: false
        },
        value: '',
        validation: {
            required: false
        },
        valid: false,
        touched: false
    },
    category: {
        label: 'Category',
        description: 'category',
        elementType: 'select',
        elementConfig: {
            type: 'select',
            placeholder: '',
            disabled: false,
            hint: 'Select category',
        },
        value: '',
        validation: { required: true },
        valid: false,
        touched: false,
        toggle: false
    },
    otherCategory: {
            label: 'Others (Category)',
            description: 'category',
            elementType: 'input', // 'select',
            elementConfig: {
                type: 'text', // 'select',
                placeholder: '',
                disabled: false,
                hint: 'Specify category',
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
    species: {
        label: 'Species',
        description: 'species',
        elementType: 'select',
        elementConfig: {
            type: 'select',
            placeholder: '',
            disabled: false,
            hint: 'Select species',
        },
        value: '',
        validation: { required: true },
        valid: false,
        touched: false,
        toggle: false
    },
    otherSpecies: {
        label: 'Others (Species)',
        description: 'species',
        elementType: 'input', // 'select',
        elementConfig: {
            type: 'text', // 'select',
            placeholder: '',
            disabled: false,
            hint: 'Specify species',
        },
        value: '',
        validation: {
            required: false
        },
        valid: true,
        touched: false
    },
    description: {
        label: 'Description',
        description: 'description',
        elementType: 'textArea',
        elementConfig: {
            type: 'text',
            placeholder: null,
            disabled: false,
            hint: 'Enter description',
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    }
};

export default geneSetAddForm;