const newGeneSetData =
{
    category: [
        'BiologicalProcess'
        ,

        'CellType'
        ,

        'CellularCompartment'
        ,

        'Disease'
        ,

        'Evolutionary'
        ,

        'GeneClass'
        ,

        'KO'
        ,

        'ProteinComplex'
        ,

        'SignalingPathway'
        ,

        'TissueType'
        ,

        'Other(Please specify)'
    ],
    species: [
        'Homo sapiens'
        ,

        'Mus musculus'
        ,

        'Rattus norvegicus'
        ,

        ' Macaca Mulatta'
        ,

        'Danio rerio'
        ,

        'Drosophila melanogaster'
        ,

        'Other(Please specify)'
    ]
}
    ;

export default newGeneSetData;