import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { setLoader } from '../../../../redux/app/app.actions';

import './tos.styles.scss';


const TermsOfService = (props) => {
    let { setLoader } = props;

    useEffect(() => {
        setLoader(false);
    }, [])

    return <Fragment>
        <div className="grid-container samples">
            {props.history?.location?.pathname?.includes('termsofservice') && <div className="grid-title big tos-title">Terms Of Service</div>}
            <div className="tos-container">
                <p><b>OMICON</b> is a portal for the collaborative study of biological systems using omics data.  It was created by The Oldham Lab, which is
                    part of the Department of Neurological Surgery at the University of California, San Francisco campus (UCSF).  OMICON content may be aggregated from published sources.
                    Content may also include images and data generated by UCSF.  OMICON may only be used for research and education purposes.</p>
                <p><b>Access to OMICON is contingent upon your acceptance of the OMICON Terms of Service including the Academic Software License, laws and UCSF policies below.</b></p>
                <p>Your access to systems and networks owned by UCSF is governed by, and subject to:</p>
                <ul>
                    <li>the following terms and conditions, including the Academic Software License;</li>
                    <li>all applicable California state and Federal laws;</li>
                    <li>the <a href="https://www.ucsf.edu/website-privacy-policy" target="_blank">UCSF Website Privacy Policy</a>;</li>
                    <li>the UCSF <a href="https://policies.ucsf.edu/policy/650-16" target="_blank">650-16: Information Security and Confidentiality</a> policy;</li>
                    <li>the UCSF <a href="https://policy.ucop.edu/doc/7000470/ElectronicCommunications" target="_blank">UC Electronic Communications Policy</a>.</li>
                </ul>
                <p>Your access to UCSF systems constitutes your consent to the retrieval and disclosure of the information within the scope of your authorized access, subject to the Privacy Act, and applicable State and Federal laws.  Your access of OMICON evidences your consent to adhere to the above listed conditions. </p>
                <div className="grid-title big tos-sub-title"><b>Academic Software License</b></div>
                <p>Academic or nonprofit researchers are permitted to use this Software (defined below) subject to the following:
                </p>
                <p>UCSF hereby grants to you free of charge, so long as you are an academic or nonprofit researcher, a nonexclusive license under UCSF’s copyright ownership interest in this software and any derivative works made by you thereof (collectively, the “Software”) to use, copy, and make derivative works of the Software solely for educational or academic purposes, and to distribute such Software free of charge to other academic and nonprofit researchers for their educational or academic research purposes, in all cases subject to the terms of this Academic Software License.  Except as granted herein, all rights are reserved by UCSF.</p>
                <p>Any distribution of copies of this Software – including any derivative works made by you thereof – must include a copy (including the copyright notice above), and be made subject to the terms of this Academic Software License; failure by you to adhere to the requirements in these Terms of Service will result in immediate termination of the license granted to you pursuant to this Academic Software License and Terms of Service effective as of the date you first used the Software.</p>
                <div className="grid-title big tos-sub-title"><b>Limitation of Liability
                </b></div>
                <p>IN NO EVENT WILL THE REGENTS OF THE UNIVERSITY OF CALIFORNIA, INCLUDING UCSF, BE LIABLE TO ANY ENTITY OR PERSON FOR DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, ARISING OUT OF THE USE OF THIS SOFTWARE, EVEN IF THE REGENTS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE REGENTS SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE SOFTWARE IS PROVIDED “AS IS.” THE REGENTS HAS NO OBLIGATION TO PROVIDE MAINTENANCE, SUPPORT, UPDATES, ENHANCEMENTS, OR MODIFICATIONS OF THIS SOFTWARE.</p>
                <div className="grid-title big tos-sub-title"><b> Acknowledgements and Attributions
                </b></div>
                <p>Any academic or scholarly publication arising from the use of this Software or any derivative works thereof will include the following acknowledgment:  The Software (<a href="https://theomicon.ucsf.edu" target="_blank">https://theomicon.ucsf.edu</a>) used in this research was created by the laboratory of Dr. Michael C. Oldham in the Department of Neurological Surgery at UCSF. © 2023 UCSF</p>
                <p>Further, any academic or scholarly publication arising from the use of this Software or any derivative works thereof must give credit where credit is due by providing proper attribution for previously published works or results accessed from the Software. </p>
                <div className="grid-title big tos-sub-title"><b>Modification of the Terms of Service</b></div>
                <p>The Regents maintains the right to modify these Terms of Service at any time and may do so by posting notice of such modifications on <a href="https://theomicon.ucsf.edu/home/termsofservice" target="_blank">https://theomicon.ucsf.edu/home/termsofservice</a>. Any modification made is effective immediately upon posting the modification (unless otherwise stated). You should visit this page periodically to review the current Terms of Service.</p>
                <div className="grid-title big tos-sub-title"><b>Conduct</b></div>
                <p>You agree to access and use OMICON for lawful <b>research and education</b> purposes only. You are solely responsible for the knowledge of and adherence to any and all laws, statutes, rules, and regulations pertaining to your use of OMICON.</p>
                <div className="grid-title big tos-sub-title"><b>By accessing and using OMICON, you agree that you must:</b></div>
                <ul>
                    <li>Access OMICON using only your own individual account. Group or shared accounts are NOT permitted.</li>
                    <li>Maintain the confidentiality of your authentication credentials such as your password.</li>
                    <li>Report all security incidents or suspected incidents (e.g., lost passwords, improper or suspicious acts) related to UCSF systems and networks to UCSF IT Security via email: ITSecurity@ucsf.edu.</li>
                    <li>Adhere to all academic and professional standards when posting comments and materials to the site.
                    </li>
                </ul>
                <div className="grid-title big tos-sub-title"><b>By accessing and using OMICON, you agree that you will NOT:</b></div>
                <ul>
                    <li>Use OMICON to commit a criminal offense, or to encourage others to conduct acts that would constitute a criminal offense or give rise to civil liability.</li>
                    <li>Post any material that infringes or violates the academic/intellectual rights of others.</li>
                    <li>Use OMICON to generate, distribute, publish, or facilitate unsolicited
                        mass email, promotions, advertisings or other solicitations or mine for
                        cryptocurrency.</li>
                    <li>Use OMICON data or code to train Artificial Intelligence (AI) or machine
                        learning models for commercial applications.
                    </li>
                </ul>
                <div className="grid-title big tos-sub-title"><b>Access Levels</b></div>
                <p>Your level of access to systems and networks owned by The Regents is limited to ensure your access is no more than necessary to perform your legitimate tasks or assigned duties. If you believe you are being granted access that you should not have, you must immediately notify The Regents via OMICON administrators by submitting a bug report through the ‘Feedback’ link on the masthead of <a href="https://theomicon.ucsf.edu">https://theomicon.ucsf.edu</a>.</p>
                <div className="grid-title big tos-sub-title"><b>Termination of Use</b></div>
                <p>The Regents may in its sole discretion suspend and/or terminate your access to OMICON without notification. The Regents may also periodically review and remove accounts for which a user has not logged on.
                </p>
                <div className="grid-title big tos-sub-title"><b>Content</b></div>
                <p>By accessing OMICON, you expressly consent to monitoring of your actions and all content or data transiting or stored therein. The Regents reserve the right to delete, move, or edit any data, which The Regents, in its sole discretion, considers unacceptable or inappropriate</p>
                <div className="grid-title big tos-sub-title"><b>Disclaimer of Warranty</b></div>
                <p>You expressly understand and agree that your use of OMICON, or any material, data or software obtained therefrom is at your own risk. OMICON is provided “as-is” and The Regents have no obligation to provide maintenance. Neither The Regents nor its employees warrant that OMICON will be uninterrupted, problem-free, free of omissions, or error-free; nor do they make any warranty as to the results that may be obtained from OMICON.  THE REGENTS SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR ANY WARRANTY OF NON-INFRINGEMENT.  </p>
                <p>Terms as of April 5, 2024</p>
            </div>
        </div>
    </Fragment>
}

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (val) => dispatch(setLoader(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TermsOfService));